/* Main Post Container */
.main-post {
  margin: 2rem 0rem; /* Spacing around the main post */
  text-align: justify; /* Justify text for clean alignment */
  font-family: "Roboto", serif; /* Use a serif font similar to Medium */
  line-height: 1.8; /* Improved line height for readability */
  color: #333; /* Dark gray text for readability */
}

/* Post Title */
.main-post h1 {
  text-align: center;
  font-size: 2rem; /* Large font size for titles */
  font-weight: 700; /* Bold title */
  margin-bottom: 1.5rem; /* Spacing below the title */
  color: #000; /* Black color for the title */
  font-family: "Montserrat", serif; /* Serif font similar to Medium */
}

/* Adjust title size for mobile screens */
@media (max-width: 768px) {
  .main-post h1 {
    font-size: 1.5rem; /* Smaller font size for mobile screens */
  }
}

/* Paragraph Styling */
.main-post p {
  font-size: 1.125rem; /* Larger font size for readability */
  margin-bottom: 1.5rem; /* Space between paragraphs */
  color: #444; /* Slightly lighter gray for paragraphs */
  line-height: 1.8; /* Relaxed line height for better readability */
  font-family: "Roboto", sans-serif;
}

/* Truncate text to three lines */
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3; /* Standard property */
  box-orient: vertical; /* Fallback for standard property */
}

/* Ensure all text is visible */
.full-text {
  display: block;
  -webkit-line-clamp: unset; /* Remove the line clamp */
  line-clamp: unset; /* Remove the line clamp */
  overflow: visible; /* Allow full overflow */
  text-overflow: clip; /* Do not truncate text */
}

/* Read More Button */
.read-more-button {
  background-color: #1a8917; /* Medium's green */
  color: white; /* White text */
  border: none; /* Remove borders */
  padding: 12px 24px; /* Larger padding for emphasis */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the link a block element */
  font-size: 18px;
  font-weight: 700;
  margin: 1rem 0; /* Add margin for spacing */
  cursor: pointer; /* Pointer/hand icon on hover */
  border-radius: 4px; /* Rounded corners */
  transition:
    background-color 0.3s ease,
    transform 0.3s ease; /* Smooth transitions */
  align-self: center; /* Center button on mobile */
}

/* Center button for mobile devices */
@media (max-width: 768px) {
  .read-more-button {
    display: block;
    width: auto;
    margin: 0 auto; /* Center the button horizontally */
    padding: 10px 20px; /* Adjust padding for better touch target */
  }
}

.read-more-button:hover {
  background-color: #136f13; /* Darker green on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

.read-more-button:focus {
  outline: none; /* Remove outline */
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* Subtle shadow on focus */
}

/* Post Date Styling */
.post-date {
  font-size: 0.875rem; /* Slightly smaller font size */
  color: #777; /* Medium gray color */
  margin-bottom: 1rem; /* Space below the date */
  font-style: italic; /* Italicize the date for subtle emphasis */
  font-family: "Helvetica Neue", "Arial", sans-serif;
}

/* Divider Styling */
.post-divider {
  border: 0;
  height: 2px;
  background: #e0e0e0; /* Light gray color */
  margin: 2rem 0; /* Spacing around the divider */
  opacity: 0.6; /* Slightly transparent for a softer look */
}
