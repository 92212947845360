/* General Section Container */
.section-container {
  width: 100%;
  max-width: 1000px;
  margin: 20px auto;
  padding: 0 20px;
  box-sizing: border-box; /* Ensure padding is included in width */
  text-align: center; /* Center align text within the section */
}

.section-container h2 {
  position: relative;
  font-size: 2rem; /* Slightly smaller font size for better mobile fit */
  color: #2c3e50;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-family: "Montserrat", serif; /* Serif font similar to Medium */
}

.section-container h2::after {
  content: "";
  position: absolute;
  bottom: 0; /* Align to the bottom of the h2 element */
  left: 0;
  width: 100%; /* Span the full width of the element */
  height: 2px; /* Height of the border */
  background: linear-gradient(to right, #fe6601, #ffcc00); /* Gradient effect */
  display: block;
}

/* County List Container */
.county-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center list items horizontally */
  gap: 20px;
}

/* County Card Styling */
.county-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  width: 220px;
  text-align: center;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.county-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.county-card p {
  font-size: 1.1rem;
  color: #34495e;
}

/* Responsive Design for Tablets and Smaller Screens */
@media (max-width: 768px) {
  .section-container {
    padding: 0 15px;
  }

  .county-list {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: center; /* Center items horizontally */
  }

  .county-card {
    width: 100%; /* Full width on mobile */
    max-width: 300px; /* Limit the width for readability */
  }
}

/* Responsive Design for Smaller Mobile Screens */
@media (max-width: 576px) {
  .section-container h2 {
    font-size: 1.5rem; /* Smaller font size for very small screens */
  }

  .county-card {
    padding: 15px;
    max-width: 280px; /* Slightly reduce width on smaller screens */
  }
}
