.category-post-list h2 {
  align-self: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
  font-weight: 600;
  line-height: 1.4;
  font-family: "Montserrat", sans-serif;
}

/* CategoryPosts.css */
.category-name {
  color: #007bff; /* Blue color */
}
