/* Post Card Container */
.post-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 2rem 0;
  border-radius: 12px;
  overflow: hidden;
  background-color: #fafafa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Image Container (Right Side) */
.post-image {
  max-width: 300px;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1.25rem;
  margin-left: 1rem;
}

/* Post Info Container (Left Side) */
.post-info {
  flex: 1;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Title Styling */
.post-info h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #2c3e50;
  font-weight: 600;
  line-height: 1.4;
  font-family: "Montserrat", sans-serif;
}

/* Category Styling */
.post-category {
  font-size: 0.75rem;
  color: #fe6601;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-family: "Montserrat", sans-serif;
}

/* Description Styling */
.post-description {
  font-size: 1.125rem;
  color: #555555;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  font-family: "Roboto", sans-serif;
}

/* Date Styling */
.post-date {
  font-size: 0.75rem;
  color: #999999;
  margin-bottom: 0.5rem;
  font-family: "Helvetica Neue", "Arial", sans-serif;
  color: #fe6601;
}

/* Read More Button */
.read-more-button {
  background-color: transparent;
  color: #1a8917;
  border: 1px solid #1a8917;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer; /* Ensure cursor is a pointer */
  border-radius: 4px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    transform 0.3s ease;
}

/* Align Button to Left on Desktop */
.post-info .read-more-button {
  align-self: flex-start;
}

/* Hover Effects for Button */
.read-more-button:hover {
  background-color: #1a8917;
  color: white;
  transform: translateY(-2px);
}

.read-more-button:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

/* Focus States for Accessibility */
.post-card:focus-within {
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.pagination button:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

/* Author Styling */
.post-author {
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  color: #555;
  margin-bottom: 0.5rem;
  font-style: italic;
}

/* Post Card Base Styling */
.post-card {
  opacity: 0.9;
  transition:
    opacity 0.2s ease,
    transform 0.2s ease;
}

.post-card.center {
  opacity: 1;
  transform: scale(1.05);
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
  .post-card {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 1rem;
  }

  .post-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  .post-info {
    width: 100%;
    text-align: center;
    padding: 1rem;
  }

  .post-info .read-more-button {
    align-self: center;
    margin: 0 auto;
  }
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  margin: 0 5px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #ddd;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.post-category-link {
  color: #007bff; /* Typical link color */
  text-decoration: underline;
  cursor: pointer;
}

.post-category-link:hover {
  color: #0056b3; /* Darker color on hover */
}
