/* Base z-index values */
:root {
  --zindex-form: 10;
  --zindex-menu: 1000;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers form elements vertically */
  z-index: var(
    --zindex-form
  ); /* Lower z-index to ensure it doesn't cover the menu */
}

.contact-form h1 {
  text-align: center;
  color: #333;
  font-weight: 300;
  font-size: 28px;
  margin-bottom: 30px;
  width: 100%; /* Ensures the header is also centered */
}

/* Form Field Styling */
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%; /* Full width for better control */
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  background: #f9f9f9;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

/* Submit Button Styling */
.contact-form input[type="submit"] {
  background-image: linear-gradient(45deg, #007bff, #66afe9);
  color: white;
  font-size: 18px;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  padding: 12px 24px;
  transition:
    background-image 0.3s,
    transform 0.3s ease;
  letter-spacing: 1px;
}

.contact-form input[type="submit"]:hover {
  background-image: linear-gradient(45deg, #0056b3, #6fa8dc);
  transform: translateY(-2px);
}

.contact-form input[type="submit"]:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.4);
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-container {
    padding: 20px;
  }

  .contact-form input[type="submit"] {
    padding: 10px;
    width: 100%; /* Full width on mobile */
    text-align: center;
  }
}

/* Subtle Animation on Input Fields */
@keyframes input-fade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  animation: input-fade 0.8s ease-out forwards;
}
