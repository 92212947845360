/* Container for the book */
.book {
  margin-bottom: 30px; /* Space between books */
  display: flex;
  width: 100%;
  align-items: flex-start; /* Align items to the top */
  gap: 20px; /* Space between image and text */
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

/* Styling for the book image */
.book-image {
  width: 175px; /* Fixed width for the image */
  max-width: 175px;
  height: 250px; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  border-radius: 5px; /* Slight rounding for the image corners */
  flex-shrink: 0; /* Prevent the image from shrinking */
}

/* Container for the book information */
.book-info {
  flex: 1; /* Allow the text container to take up remaining space */
  font-family: "Roboto", sans-serif;
  color: #292929; /* Dark gray text for readability */
  position: relative; /* Allow absolute positioning of child elements */
}

/* Title styling */
.book-info h2 {
  font-family: "Montserrat", serif; /* Medium-like serif font for headings */
  font-size: 1.75rem; /* Larger font size for the title */
  margin: 0; /* Remove margins to avoid background gaps */
  font-weight: 700; /* Bold title */
  line-height: 1.3; /* Adjust line height for readability */
  position: relative;
  color: #000; /* Black color for the title */
}

/* Description styling */
.book-description {
  font-size: 1.125rem; /* Regular font size for the description */
  line-height: 1.8; /* Increased line height for readability */
  color: #555; /* Medium gray for the description text */
  margin-bottom: 10px; /* Space below the description */
  text-align: justify;
  font-family: "Roboto", sans-serif;
}

/* Author styling */
.book-author {
  font-family: "Roboto", sans-serif; /* Medium-like serif font */
  font-size: 0.875rem;
  color: #555;
  margin-bottom: 0.5rem;
  font-style: italic;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .book {
    width: 100%;
    flex-direction: column; /* Stack image and text vertically on smaller screens */
    align-items: center; /* Center align items for better readability */
  }

  .book-image {
    width: 100%; /* Full width image on smaller screens */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 15px; /* Add margin below the image for spacing */
  }

  .book-info {
    text-align: center; /* Center text on smaller screens */
  }

  .book-info h2 {
    font-size: 1.5rem; /* Slightly smaller title font size for better fit on small screens */
  }

  .book-description {
    font-size: 1.125rem; /* Slightly smaller text for readability on small screens */
    line-height: 1.6; /* Adjust line height for smaller screens */
  }

  .book-author {
    font-size: 1rem; /* Adjust author text size for better fit on small screens */
  }
}
