/* General Section Styles */
#education,
#certificates,
#scholar,
#languages,
#work_experience {
  width: 100%;
  max-width: 900px;
  margin: 30px auto; /* Center sections */
  padding: 25px;
  background-color: #ffffff; /* White background for content blocks */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
  border: 1px solid #e0e0e0; /* Subtle border */
  box-sizing: border-box; /* Include padding in element width */
}

/* List Styles */
#work_experience ul {
  list-style: inside disc; /* Elegant disc list inside the container */
  padding-left: 0; /* No extra padding */
}

#work_experience li {
  margin-bottom: 12px; /* Space between list items */
}

/* Section Header Styles */
#right-section h1 {
  position: relative;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 2.5rem; /* Large font size similar to example */
  font-weight: 700; /* Bold font */
  color: #007bff; /* Theme color for headers */
  padding-bottom: 12px;
  padding-top: 20px;
  text-align: center; /* Centered header text */
  font-family: "Merriweather", serif; /* Serif font similar to Medium */
  text-align: justify; /* Justify the text */
}

#right-section h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #fe6601, #ffcc00); /* Gradient effect */
  display: block;
}

/* Adjust title size for mobile screens */
@media (max-width: 768px) {
  #right-section h1 {
    font-size: 1.75rem; /* Smaller font size for mobile screens */
  }
}

/* Paragraph and Text Styles */
#summary p {
  padding-bottom: 10px;
  margin: 10px 0;
  line-height: 1.8; /* Improved line height for readability */
  font-size: 1.125rem; /* Font size similar to the example */
  color: #555; /* Darker grey for better readability */
  text-align: justify;
  font-family: "Georgia", serif; /* Use a serif font similar to Medium */
  font-weight: 400; /* Normal weight for summary text */
}

/* Title and Job/Education Titles */
#job-title,
#ed-title {
  margin: 10px 0;
  line-height: 1.8; /* Improved line height for readability */
  font-size: 1.125rem; /* Font size similar to the example */
  font-weight: 600; /* Slightly bolder font for titles */
  color: black;
}

/* Horizontal Rule Styles */
hr {
  border: none;
  height: 1px;
  background-color: #ccc; /* Soft HR color */
  margin: 20px 0;
}

/* Responsive Design - Tablet and Small Screens */
@media (max-width: 992px) {
  #education,
  #certificates,
  #scholar,
  #languages,
  #work_experience {
    padding: 20px;
    margin: 20px auto; /* Ensure sections remain centered */
  }

  #summary p,
  #job-title,
  #ed-title {
    font-size: 1.125rem; /* Adjust text size for readability */
    line-height: 1.7; /* Slightly adjust line height */
  }
}

/* Responsive Design - Mobile Devices */
@media (max-width: 768px) {
  #education,
  #certificates,
  #scholar,
  #languages,
  #work_experience {
    width: 90%; /* Slightly narrower sections */
    padding: 15px;
    margin: 15px auto; /* Center sections on small screens */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow */
  }

  #summary p,
  #job-title,
  #ed-title {
    font-size: 1.125rem; /* Adjust text size for readability on mobile */
    line-height: 1.7; /* Maintain line height */
  }

  hr {
    margin: 15px 0; /* Tighter layout for mobile */
  }
}

/* Responsive Design - Extra Small Screens */
@media (max-width: 576px) {
  #education,
  #certificates,
  #scholar,
  #languages,
  #work_experience {
    width: 95%; /* Full width with small margin */
    padding: 10px;
    margin: 10px auto; /* Center sections on very small screens */
  }

  #summary p,
  #job-title,
  #ed-title {
    font-size: 1.125rem; /* Adjust font size for summary text on extra small screens */
    line-height: 1.7;
  }

  hr {
    margin: 10px 0; /* Adjust margin for tight layout */
  }
}
