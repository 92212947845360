html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to right, #ece9e6, #ffffff);
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1; /* Ensures that the main content takes up the space it needs */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%; /* Default width */
  max-width: 1200px; /* Set a max-width to limit the container's width */
  padding: 20px; /* For spacing within the container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  flex-direction: column;
}

#main_info {
  background-color: white;
  width: 100%;
  display: flex;
  vertical-align: baseline;
  justify-content: center;
  padding: 25px 25px 25px;
  flex-direction: column;
  border-radius: 10px 10px 0;
  /*background: linear-gradient(90deg, #fe6601, #f5caad);*/
}

#main_info h1 {
  width: 100%;
  font-size: 50px; /* Default font size */
  line-height: 1.1em;
  font-weight: 400;
  font-family: Libre Baskerville;
  text-align: left;
  text-shadow: 2px 5px 10px rgb(70, 68, 68);
  color: #fe6601;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .main-container {
    width: 100%; /* Increase width for mobile screens */
    padding: 20px; /* Maintain padding */
  }

  #main_info h1 {
    font-size: 36px; /* Smaller title font size for mobile */
    text-align: center; /* Center the title on mobile */
  }

  #main_info p,
  #main_info {
    font-size: 16px; /* Smaller paragraph and general font size for mobile */
    line-height: 1.5em; /* Adjust line height for better readability on mobile */
  }
}
