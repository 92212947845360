#reading_section {
  margin-top: 40px;
  width: 100%; /* Set the width to 100% */
  margin-left: auto;
  margin-right: auto;
}

#reading_section .container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  align-items: center; /* Center content horizontally */
}

#reading_section h1 {
  text-align: center;
  font-size: 2.5rem; /* Font size similar to the example */
  font-weight: 700; /* Bold font */
  font-family: "Montserrat", serif; /* Serif font similar to Medium */
  line-height: 1.2em; /* Adjusted line height for readability */
  margin: 20px;
}

#reading_section h2 {
  text-align: center;
  font-family: "Montserrat", serif;
  font-size: 1.125rem; /* Adjusted font size for readability */
  line-height: 1.8em; /* Line height similar to the example */
  margin-bottom: 25px;
}

.books {
  display: flex;
  align-content: space-between;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 20px; /* Add space between books */
}

.year {
  font-size: 2rem; /* Adjusted font size to match the example */
  font-weight: 600; /* Slightly bold for emphasis */
  font-family: "Helvetica Neue", "Arial", sans-serif;
}
