/* General page container */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px; /* Reduced maximum width */
  margin: auto;
  padding: 15px; /* Reduced padding */
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06); /* Lighter, more subtle shadow */
}

/* Blog posts styling */
.blog-posts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px; /* Reduced gap for tighter layout */
}

.post-item {
  background: #f9f9f9;
  padding: 12px; /* Reduced padding */
  border-radius: 8px;
  width: calc(25% - 15px); /* Smaller width for more items per row */
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.post-image {
  width: 100%;
  height: auto;
  border-radius: 6px; /* Smaller radius */
  overflow: hidden;
}

.post-title,
.post-date {
  color: #333;
  font-weight: 500;
  margin-bottom: 5px; /* Smaller margin */
}

/* Read more button styling */
.read-more {
  padding: 10px 18px;
  background-color: #5c67f2;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  text-align: center;
  transition: background-color 0.3s;
}

.read-more:hover {
  background-color: #434aee;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15); /* Subtle hover effect */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
  .post-item {
    width: calc(33.333% - 15px); /* Adjust for medium screens */
  }
}

@media (max-width: 768px) {
  .post-item {
    width: calc(50% - 15px); /* Adjust for smaller screens */
  }
}

@media (max-width: 480px) {
  .post-item {
    width: 100%; /* Full width on very small screens */
  }
}
