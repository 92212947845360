/* General Container */
.post-detail {
  margin: 0 auto; /* Center the content */
  font-family: "Roboto", sans-serif;
  line-height: 1.8; /* Improve readability with generous line height */
  color: #292929; /* Dark gray text color */
}

/* Title Styling */
.post-detail h1 {
  text-align: center;
  font-size: 2.5rem; /* Large font size for impact */
  font-weight: 700; /* Bold font */
  line-height: 1.2; /* Title line height */
  margin-bottom: 1rem; /* Space below the title */
  color: #000; /* Black color for the title */
  font-family: "Montserrat", serif; /* Serif font for title */
}

/* Image Styling */
.post-detail img {
  display: block; /* Ensure block-level behavior */
  margin: 0 auto; /* Center the image horizontally */
  width: 100%; /* Full-width image */
  max-width: 720px; /* Max width to match container */
  border-radius: 8px; /* Subtle rounded corners */
}

/* Category Styling */
.post-category-detail {
  font-family: "Roboto", sans-serif; /* Sans-serif font for category */
  font-size: 0.875rem; /* Small font size */
  color: #fe6601; /* Light gray color */
  text-transform: uppercase; /* Uppercase text */
  letter-spacing: 0.1em; /* Spaced letters */
  margin-bottom: 1rem; /* Space below category */
  margin-top: 1rem;
}

/* Date Styling */
.post-date-detail {
  font-family: "Roboto", sans-serif; /* Sans-serif font for the date */
  font-size: 0.875rem; /* Small font size */
  color: #757575; /* Light gray color */
  margin-bottom: 1rem; /* Space below the date */
  text-align: left; /* Align text to the left */
}

/* Body Text Styling */
.post-body-detail {
  font-size: 1.125rem; /* Medium's body font size */
  color: #292929; /* Dark gray text color for readability */
  text-align: justify; /* Justify text alignment */
  margin-bottom: 2rem; /* Space below the body text */
}

.post-body-detail p {
  margin-bottom: 1.5rem; /* Space between paragraphs */
  line-height: 1.8; /* Line height for paragraphs */
  font-size: 1.125rem; /* Consistent font size for paragraphs */
}

/* Headings within the Body */
.post-body-detail h2,
.post-body-detail h3 {
  font-family: "Montserrat", serif; /* Serif font for headings */
  color: #000; /* Black color for headings */
  font-weight: 700; /* Bold headings */
  margin-top: 2rem; /* Space above headings */
  margin-bottom: 1rem; /* Space below headings */
  line-height: 1.4; /* Tighter line height for headings */
}

/* Blockquote Styling */
.post-body-detail blockquote {
  font-style: italic; /* Italicize blockquotes */
  color: #555; /* Medium gray color */
  border-left: 4px solid #e0e0e0; /* Light gray left border */
  padding-left: 1rem; /* Padding inside blockquote */
  margin: 2rem 0; /* Space around blockquotes */
  font-family: "Montserrat", serif; /* Serif font for blockquotes */
}

/* List Styling */
.post-body-detail ul,
.post-body-detail ol {
  margin-left: 2rem; /* Indent lists */
  margin-bottom: 1.5rem; /* Space below lists */
  line-height: 1.8; /* Line height for lists */
}

/* Link Styling */
.post-body-detail a {
  color: #1a73e8; /* Medium-blue color for links */
  text-decoration: none; /* No underline */
  border-bottom: 1px solid #1a73e8; /* Subtle underline on hover */
}

.post-body-detail a:hover {
  border-bottom: 2px solid #1a73e8; /* Slightly thicker underline on hover */
}

/* Share Icons */
.share-icons {
  margin-top: 2rem; /* Space above the share section */
  text-align: center; /* Center the share section */
}

.share-icons span {
  display: block;
  font-size: 1rem;
  color: #757575; /* Light gray color */
  margin-bottom: 1rem;
}

.share-icons a {
  margin: 0 10px; /* Space between icons */
  color: #1a73e8; /* Default color for icons */
  font-size: 1.5rem; /* Increase icon size */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.share-icons a:hover {
  color: #000; /* Darken color on hover */
}

/* Responsive Styling for Mobile */
@media (max-width: 768px) {
  .post-detail {
    max-width: 100%; /* Make content area wider on mobile */
  }

  .post-category-detail {
    margin-top: 1rem;
  }

  .post-detail h1 {
    font-size: 2rem; /* Smaller title font size on mobile */
  }

  .post-body-detail {
    font-size: 1.125rem; /* Keep body text size consistent */
  }

  .post-date-detail {
  }

  .post-body-detail p {
    font-size: 1.125rem; /* Keep paragraph text size consistent */
  }

  .share-icons a {
    font-size: 1.25rem; /* Slightly smaller icons on mobile */
  }
}

.post-category-link {
  color: #007bff; /* Typical link color */
  text-decoration: underline;
  cursor: pointer;
}

.post-category-link:hover {
  color: #0056b3; /* Darker color on hover */
}
