.footer-container {
  background: linear-gradient(90deg, #4b79a1, #283e51);
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container p {
  margin: 5px 0;
}

.social-icons {
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  gap: 15px;
  margin-top: 10px;
}

.social-icon {
  color: #ffffff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #61dafb;
}
