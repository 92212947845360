/* General Styles */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background: linear-gradient(90deg, #4b79a1, #283e51);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  z-index: var(--zindex-menu);
}

/* Hamburger Menu Styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  margin: auto; /* Center the hamburger */
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  margin: 4px 0;
  transition: 0.3s;
}

/* Navigation Menu Styles */
.nav-menu {
  display: flex;
  gap: 20px;
}

.nav-link {
  font-size: 18px;
  text-decoration: none;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 5px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    transform 0.3s ease;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
}

/* Login Button Styles */
.login-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #61dafb;
  color: #283e51;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    transform 0.3s ease;
}

.login-button:hover {
  background-color: #21a1f1;
  transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
    align-self: center;
  }

  .nav-menu {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #283e51;
    padding: 10px 0;
    display: none;
    align-items: center; /* Center menu items */
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-link {
    padding: 15px 20px;
    width: 100%;
    text-align: center;
  }

  .login-button {
    display: none; /* Hide login button on mobile */
  }
}
