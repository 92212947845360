/* src/styles/Categories.css */
.categories h2 {
  color: black;
  font-family: "Montserrat", sans-serif;
  text-align: center; /* Centers the text horizontally within the h2 element */
}

.categories ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto; /* Center the list horizontally in its container */
  display: flex; /* Makes the list horizontal */
  flex-wrap: wrap; /* Allows list items to wrap if space is insufficient */
  gap: 10px; /* Adds space between items */
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Optional: align items vertically if needed */
  max-width: 100%; /* Maximum width of the list, adjust as needed */
}

.categories li {
  padding: 8px;
  margin: 0; /* Adjust as necessary, remove if you want items closer */
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.categories a {
  /* Styling for links */
  text-decoration: none; /* Removes underline */
  color: #007bff; /* Sets the text color */
  display: block; /* Makes the link fill the li for better clickability */
}

.categories li:hover {
  background-color: #e0e0e0; /* Lighter background on hover for better interaction feedback */
}

.categories a:hover {
  color: #000; /* Darker text on hover */
}
