/* Book List Container */
.book-list {
  max-width: 100%;
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
}

/* Year Group Container */
.year-group {
  margin-bottom: 40px;
}

/* Year Title Styling */
.year-title {
  position: relative;
  font-family: "Helvetica Neue", "Arial", sans-serif; /* Medium-like sans-serif font for headings */
  font-size: 2.5rem; /* Adjusted size to be slightly smaller for better readability */
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  padding-bottom: 10px;
  line-height: 1.3; /* Adjusted line height for readability */
}

.year-title::after {
  content: "";
  position: absolute;
  bottom: 0; /* Align to the bottom of the text */
  left: 0;
  width: 100%; /* Full width of the element */
  height: 2px; /* Thickness of the border */
  background: linear-gradient(
    to right,
    #fe6601,
    #ffcc00
  ); /* Horizontal gradient */
  display: block;
}

/* Books Container */
.books-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
