.not-found-container {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f7f8fc;
  color: #333;
  padding: 20px;
}

.not-found-title {
  font-size: 6rem;
  font-weight: 800;
  color: #ff6b6b;
  margin: 0;
}

.not-found-message {
  font-size: 1.5rem;
  margin: 20px 0;
  color: #555;
}

.not-found-redirect {
  font-size: 1rem;
  color: #999;
}

@media (max-width: 768px) {
  .not-found-title {
    font-size: 4rem;
  }

  .not-found-message {
    font-size: 1.25rem;
  }
}
