.search-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 90%;
  margin: 15px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 12px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.search-input-container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #007bff;
}

.close-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: black;
  cursor: pointer;
  font-size: 1.25rem;
}

.search-input {
  width: 100%;
  padding: 10px 40px 10px 40px; /* Adjusted padding to accommodate both icons */
  border: 1px solid #ccc;
  border-radius: 12px;
  font-size: 1.125rem;
  box-sizing: border-box;
}

.search-results {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.search-result-item {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  padding: 10px 0px;
  border-bottom: 1px solid #ddd;
}

.search-result-image {
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 0px;
  align-self: center;
}

.search-result-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 5px 15px;
}

.search-result-text h3 {
  margin: 0px;
  font-size: 1rem;
  color: #007bff;
}

.search-result-text p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #555;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  text-align: justify;
}

.search-result-text small {
  font-size: 0.8rem;
  color: #888;
}

.search-result-item:hover h3 {
  text-decoration: underline;
}

.close-icon:hover {
  font-size: 1.6rem;
}

@media (max-width: 768px) {
  .search-result-item {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
  }

  .search-result-image {
    max-width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .search-result-text h3 {
    margin: 0;
    font-size: 1rem;
    color: #007bff;
    text-align: center;
  }
}
